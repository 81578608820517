import './preload.css';
import loader from './../../assets/loader.gif';


function Preload({isPreOut}) {
    return (
    <div id="preload" className={`${isPreOut ? "slide" : ""}`}>

            <svg id="preload_shape1" className="preload_shapes" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="489.12" height="326.82" viewBox="0 0 489.12 326.82">
                <defs>
                    <linearGradient id="linear-gradient" x1="-2.254" y1="11.103" x2="-1.254" y2="11.103" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#f79b79"/>
                    <stop offset="1" stopColor="#ed3b64"/>
                    </linearGradient>
                    <linearGradient id="linear-gradient-2" x1="-4.627" y1="10.12" x2="-3.628" y2="10.12" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-3" x1="-7.001" y1="9.136" x2="-6.001" y2="9.136" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-4" x1="-9.375" y1="8.154" x2="-8.375" y2="8.154" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-5" x1="-11.748" y1="7.171" x2="-10.748" y2="7.171" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-6" x1="-14.122" y1="6.188" x2="-13.122" y2="6.188" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-7" x1="-16.495" y1="5.204" x2="-15.495" y2="5.204" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-8" x1="-1.439" y1="9.134" x2="-0.439" y2="9.134" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-9" x1="-3.812" y1="8.15" x2="-2.812" y2="8.15" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-10" x1="-6.186" y1="7.168" x2="-5.186" y2="7.168" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-11" x1="-8.559" y1="6.184" x2="-7.559" y2="6.184" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-12" x1="-10.932" y1="5.201" x2="-9.932" y2="5.201" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-13" x1="-13.306" y1="4.218" x2="-12.306" y2="4.218" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-14" x1="-15.679" y1="3.235" x2="-14.679" y2="3.235" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-15" x1="-0.623" y1="7.164" x2="0.377" y2="7.164" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-16" x1="-2.996" y1="6.181" x2="-1.996" y2="6.181" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-17" x1="-5.369" y1="5.198" x2="-4.37" y2="5.198" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-18" x1="-7.743" y1="4.215" x2="-6.743" y2="4.215" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-19" x1="-10.116" y1="3.232" x2="-9.116" y2="3.232" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-20" x1="-12.49" y1="2.248" x2="-11.49" y2="2.248" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-21" x1="-14.864" y1="1.265" x2="-13.864" y2="1.265" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-22" x1="0.193" y1="5.195" x2="1.193" y2="5.195" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-23" x1="-2.18" y1="4.212" x2="-1.18" y2="4.212" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-24" x1="-4.554" y1="3.228" x2="-3.554" y2="3.228" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-25" x1="-6.927" y1="2.245" x2="-5.928" y2="2.245" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-26" x1="-9.301" y1="1.263" x2="-8.301" y2="1.263" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-27" x1="-11.674" y1="0.279" x2="-10.674" y2="0.279" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-28" x1="-14.047" y1="-0.704" x2="-13.048" y2="-0.704" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-29" x1="1.009" y1="3.226" x2="2.009" y2="3.226" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-30" x1="-1.364" y1="2.242" x2="-0.365" y2="2.242" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-31" x1="-3.738" y1="1.259" x2="-2.738" y2="1.259" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-32" x1="-6.111" y1="0.276" x2="-5.111" y2="0.276" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-33" x1="-8.485" y1="-0.707" x2="-7.485" y2="-0.707" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-34" x1="-10.858" y1="-1.691" x2="-9.858" y2="-1.691" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-35" x1="-13.232" y1="-2.673" x2="-12.232" y2="-2.673" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-36" x1="1.825" y1="1.256" x2="2.825" y2="1.256" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-37" x1="-0.549" y1="0.272" x2="0.451" y2="0.272" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-38" x1="-2.922" y1="-0.71" x2="-1.922" y2="-0.71" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-39" x1="-5.296" y1="-1.693" x2="-4.296" y2="-1.693" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-40" x1="-7.669" y1="-2.677" x2="-6.669" y2="-2.677" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-41" x1="-10.043" y1="-3.66" x2="-9.043" y2="-3.66" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-42" x1="-12.416" y1="-4.643" x2="-11.416" y2="-4.643" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-43" x1="2.64" y1="-0.713" x2="3.64" y2="-0.713" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-44" x1="0.267" y1="-1.697" x2="1.267" y2="-1.697" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-45" x1="-2.106" y1="-2.68" x2="-1.107" y2="-2.68" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-46" x1="-4.48" y1="-3.663" x2="-3.48" y2="-3.663" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-47" x1="-6.853" y1="-4.646" x2="-5.853" y2="-4.646" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-48" x1="-9.226" y1="-5.629" x2="-8.227" y2="-5.629" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-49" x1="-11.6" y1="-6.613" x2="-10.6" y2="-6.613" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-50" x1="3.457" y1="-2.683" x2="4.456" y2="-2.683" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-51" x1="1.083" y1="-3.666" x2="2.083" y2="-3.666" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-52" x1="-1.29" y1="-4.649" x2="-0.29" y2="-4.649" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-53" x1="-3.664" y1="-5.633" x2="-2.664" y2="-5.633" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-54" x1="-6.038" y1="-6.615" x2="-5.038" y2="-6.615" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-55" x1="-8.411" y1="-7.599" x2="-7.411" y2="-7.599" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-56" x1="-10.784" y1="-8.582" x2="-9.785" y2="-8.582" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-57" x1="4.272" y1="-4.652" x2="5.272" y2="-4.652" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-58" x1="1.898" y1="-5.636" x2="2.898" y2="-5.636" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-59" x1="-0.475" y1="-6.619" x2="0.525" y2="-6.619" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-60" x1="-2.848" y1="-7.602" x2="-1.848" y2="-7.602" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-61" x1="-5.222" y1="-8.585" x2="-4.222" y2="-8.585" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-62" x1="-7.595" y1="-9.569" x2="-6.595" y2="-9.569" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-63" x1="-9.968" y1="-10.551" x2="-8.968" y2="-10.551" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-64" x1="5.088" y1="-6.622" x2="6.088" y2="-6.622" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-65" x1="2.715" y1="-7.605" x2="3.714" y2="-7.605" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-66" x1="0.341" y1="-8.588" x2="1.341" y2="-8.588" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-67" x1="-2.032" y1="-9.571" x2="-1.032" y2="-9.571" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-68" x1="-4.406" y1="-10.554" x2="-3.406" y2="-10.554" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-69" x1="-6.779" y1="-11.538" x2="-5.779" y2="-11.538" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-70" x1="-9.153" y1="-12.521" x2="-8.153" y2="-12.521" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-71" x1="5.904" y1="-8.591" x2="6.904" y2="-8.591" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-72" x1="3.53" y1="-9.575" x2="4.53" y2="-9.575" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-73" x1="1.157" y1="-10.558" x2="2.157" y2="-10.558" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-74" x1="-1.217" y1="-11.541" x2="-0.217" y2="-11.541" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-75" x1="-3.59" y1="-12.524" x2="-2.59" y2="-12.524" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-76" x1="-5.963" y1="-13.507" x2="-4.964" y2="-13.507" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-77" x1="-8.337" y1="-14.49" x2="-7.337" y2="-14.49" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-78" x1="6.719" y1="-10.561" x2="7.719" y2="-10.561" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-79" x1="4.346" y1="-11.544" x2="5.346" y2="-11.544" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-80" x1="1.973" y1="-12.527" x2="2.973" y2="-12.527" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-81" x1="-0.4" y1="-13.51" x2="0.599" y2="-13.51" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-82" x1="-2.774" y1="-14.493" x2="-1.774" y2="-14.493" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-83" x1="-5.147" y1="-15.477" x2="-4.147" y2="-15.477" xlinkHref="#linear-gradient"/>
                    <linearGradient id="linear-gradient-84" x1="-7.521" y1="-16.46" x2="-6.521" y2="-16.46" xlinkHref="#linear-gradient"/>
                </defs>
                <g id="Group_3" data-name="Group 3" transform="translate(-1431.14 -18.74)" opacity="0.1">
                    <path id="Path_7" data-name="Path 7" d="M1912.53,34.2a7.73,7.73,0,1,1,7.73-7.73A7.729,7.729,0,0,1,1912.53,34.2Z" fill="url(#linear-gradient)"/>
                    <path id="Path_8" data-name="Path 8" d="M1912.53,86.1a7.73,7.73,0,1,1,7.73-7.73A7.729,7.729,0,0,1,1912.53,86.1Z" fill="url(#linear-gradient-2)"/>
                    <path id="Path_9" data-name="Path 9" d="M1912.53,137.99a7.73,7.73,0,1,1,7.73-7.73A7.729,7.729,0,0,1,1912.53,137.99Z" fill="url(#linear-gradient-3)"/>
                    <path id="Path_10" data-name="Path 10" d="M1912.53,189.88a7.73,7.73,0,1,1,7.73-7.73A7.729,7.729,0,0,1,1912.53,189.88Z" fill="url(#linear-gradient-4)"/>
                    <path id="Path_11" data-name="Path 11" d="M1912.53,241.77a7.73,7.73,0,1,1,7.73-7.73A7.729,7.729,0,0,1,1912.53,241.77Z" fill="url(#linear-gradient-5)"/>
                    <path id="Path_12" data-name="Path 12" d="M1912.53,293.66a7.73,7.73,0,1,1,7.73-7.73A7.729,7.729,0,0,1,1912.53,293.66Z" fill="url(#linear-gradient-6)"/>
                    <path id="Path_13" data-name="Path 13" d="M1912.53,345.56a7.73,7.73,0,1,1,7.73-7.73A7.729,7.729,0,0,1,1912.53,345.56Z" fill="url(#linear-gradient-7)"/>
                    <path id="Path_14" data-name="Path 14" d="M1869.47,34.2a7.73,7.73,0,1,1,7.73-7.73A7.729,7.729,0,0,1,1869.47,34.2Z" fill="url(#linear-gradient-8)"/>
                    <circle id="Ellipse_2" data-name="Ellipse 2" cx="7.73" cy="7.73" r="7.73" transform="translate(1861.74 70.64)" fill="url(#linear-gradient-9)"/>
                    <circle id="Ellipse_3" data-name="Ellipse 3" cx="7.73" cy="7.73" r="7.73" transform="translate(1861.74 122.53)" fill="url(#linear-gradient-10)"/>
                    <circle id="Ellipse_4" data-name="Ellipse 4" cx="7.73" cy="7.73" r="7.73" transform="translate(1861.74 174.42)" fill="url(#linear-gradient-11)"/>
                    <circle id="Ellipse_5" data-name="Ellipse 5" cx="7.73" cy="7.73" r="7.73" transform="translate(1861.74 226.31)" fill="url(#linear-gradient-12)"/>
                    <circle id="Ellipse_6" data-name="Ellipse 6" cx="7.73" cy="7.73" r="7.73" transform="translate(1861.74 278.21)" fill="url(#linear-gradient-13)"/>
                    <circle id="Ellipse_7" data-name="Ellipse 7" cx="7.73" cy="7.73" r="7.73" transform="translate(1861.74 330.1)" fill="url(#linear-gradient-14)"/>
                    <path id="Path_15" data-name="Path 15" d="M1826.41,34.2a7.73,7.73,0,1,1,7.73-7.73A7.729,7.729,0,0,1,1826.41,34.2Z" fill="url(#linear-gradient-15)"/>
                    <path id="Path_16" data-name="Path 16" d="M1826.41,86.1a7.73,7.73,0,1,1,7.73-7.73A7.729,7.729,0,0,1,1826.41,86.1Z" fill="url(#linear-gradient-16)"/>
                    <path id="Path_17" data-name="Path 17" d="M1826.41,137.99a7.73,7.73,0,1,1,7.73-7.73A7.729,7.729,0,0,1,1826.41,137.99Z" fill="url(#linear-gradient-17)"/>
                    <path id="Path_18" data-name="Path 18" d="M1826.41,189.88a7.73,7.73,0,1,1,7.73-7.73A7.729,7.729,0,0,1,1826.41,189.88Z" fill="url(#linear-gradient-18)"/>
                    <path id="Path_19" data-name="Path 19" d="M1826.41,241.77a7.73,7.73,0,1,1,7.73-7.73A7.729,7.729,0,0,1,1826.41,241.77Z" fill="url(#linear-gradient-19)"/>
                    <path id="Path_20" data-name="Path 20" d="M1826.41,293.67a7.73,7.73,0,1,1,7.73-7.73A7.729,7.729,0,0,1,1826.41,293.67Z" fill="url(#linear-gradient-20)"/>
                    <path id="Path_21" data-name="Path 21" d="M1826.41,345.56a7.73,7.73,0,1,1,7.73-7.73A7.729,7.729,0,0,1,1826.41,345.56Z" fill="url(#linear-gradient-21)"/>
                    <path id="Path_22" data-name="Path 22" d="M1783.35,34.2a7.73,7.73,0,1,1,7.73-7.73A7.729,7.729,0,0,1,1783.35,34.2Z" fill="url(#linear-gradient-22)"/>
                    <circle id="Ellipse_8" data-name="Ellipse 8" cx="7.73" cy="7.73" r="7.73" transform="translate(1775.62 70.64)" fill="url(#linear-gradient-23)"/>
                    <circle id="Ellipse_9" data-name="Ellipse 9" cx="7.73" cy="7.73" r="7.73" transform="translate(1775.62 122.53)" fill="url(#linear-gradient-24)"/>
                    <circle id="Ellipse_10" data-name="Ellipse 10" cx="7.73" cy="7.73" r="7.73" transform="translate(1775.62 174.42)" fill="url(#linear-gradient-25)"/>
                    <circle id="Ellipse_11" data-name="Ellipse 11" cx="7.73" cy="7.73" r="7.73" transform="translate(1775.62 226.31)" fill="url(#linear-gradient-26)"/>
                    <circle id="Ellipse_12" data-name="Ellipse 12" cx="7.73" cy="7.73" r="7.73" transform="translate(1775.62 278.21)" fill="url(#linear-gradient-27)"/>
                    <circle id="Ellipse_13" data-name="Ellipse 13" cx="7.73" cy="7.73" r="7.73" transform="translate(1775.62 330.1)" fill="url(#linear-gradient-28)"/>
                    <path id="Path_23" data-name="Path 23" d="M1740.29,34.2a7.73,7.73,0,1,1,7.73-7.73A7.729,7.729,0,0,1,1740.29,34.2Z" fill="url(#linear-gradient-29)"/>
                    <circle id="Ellipse_14" data-name="Ellipse 14" cx="7.73" cy="7.73" r="7.73" transform="translate(1732.56 70.64)" fill="url(#linear-gradient-30)"/>
                    <circle id="Ellipse_15" data-name="Ellipse 15" cx="7.73" cy="7.73" r="7.73" transform="translate(1732.56 122.53)" fill="url(#linear-gradient-31)"/>
                    <circle id="Ellipse_16" data-name="Ellipse 16" cx="7.73" cy="7.73" r="7.73" transform="translate(1732.56 174.42)" fill="url(#linear-gradient-32)"/>
                    <circle id="Ellipse_17" data-name="Ellipse 17" cx="7.73" cy="7.73" r="7.73" transform="translate(1732.56 226.31)" fill="url(#linear-gradient-33)"/>
                    <circle id="Ellipse_18" data-name="Ellipse 18" cx="7.73" cy="7.73" r="7.73" transform="translate(1732.56 278.21)" fill="url(#linear-gradient-34)"/>
                    <circle id="Ellipse_19" data-name="Ellipse 19" cx="7.73" cy="7.73" r="7.73" transform="translate(1732.56 330.1)" fill="url(#linear-gradient-35)"/>
                    <path id="Path_24" data-name="Path 24" d="M1697.23,34.2a7.73,7.73,0,1,1,7.73-7.73A7.729,7.729,0,0,1,1697.23,34.2Z" fill="url(#linear-gradient-36)"/>
                    <circle id="Ellipse_20" data-name="Ellipse 20" cx="7.73" cy="7.73" r="7.73" transform="translate(1689.5 70.64)" fill="url(#linear-gradient-37)"/>
                    <circle id="Ellipse_21" data-name="Ellipse 21" cx="7.73" cy="7.73" r="7.73" transform="translate(1689.5 122.53)" fill="url(#linear-gradient-38)"/>
                    <circle id="Ellipse_22" data-name="Ellipse 22" cx="7.73" cy="7.73" r="7.73" transform="translate(1689.5 174.42)" fill="url(#linear-gradient-39)"/>
                    <circle id="Ellipse_23" data-name="Ellipse 23" cx="7.73" cy="7.73" r="7.73" transform="translate(1689.5 226.31)" fill="url(#linear-gradient-40)"/>
                    <circle id="Ellipse_24" data-name="Ellipse 24" cx="7.73" cy="7.73" r="7.73" transform="translate(1689.5 278.21)" fill="url(#linear-gradient-41)"/>
                    <circle id="Ellipse_25" data-name="Ellipse 25" cx="7.73" cy="7.73" r="7.73" transform="translate(1689.5 330.1)" fill="url(#linear-gradient-42)"/>
                    <path id="Path_25" data-name="Path 25" d="M1654.17,34.2a7.73,7.73,0,1,1,7.73-7.73A7.729,7.729,0,0,1,1654.17,34.2Z" fill="url(#linear-gradient-43)"/>
                    <circle id="Ellipse_26" data-name="Ellipse 26" cx="7.73" cy="7.73" r="7.73" transform="translate(1646.44 70.64)" fill="url(#linear-gradient-44)"/>
                    <circle id="Ellipse_27" data-name="Ellipse 27" cx="7.73" cy="7.73" r="7.73" transform="translate(1646.44 122.53)" fill="url(#linear-gradient-45)"/>
                    <circle id="Ellipse_28" data-name="Ellipse 28" cx="7.73" cy="7.73" r="7.73" transform="translate(1646.44 174.42)" fill="url(#linear-gradient-46)"/>
                    <circle id="Ellipse_29" data-name="Ellipse 29" cx="7.73" cy="7.73" r="7.73" transform="translate(1646.44 226.31)" fill="url(#linear-gradient-47)"/>
                    <circle id="Ellipse_30" data-name="Ellipse 30" cx="7.73" cy="7.73" r="7.73" transform="translate(1646.44 278.21)" fill="url(#linear-gradient-48)"/>
                    <circle id="Ellipse_31" data-name="Ellipse 31" cx="7.73" cy="7.73" r="7.73" transform="translate(1646.44 330.1)" fill="url(#linear-gradient-49)"/>
                    <path id="Path_26" data-name="Path 26" d="M1611.11,34.2a7.73,7.73,0,1,1,7.73-7.73A7.729,7.729,0,0,1,1611.11,34.2Z" fill="url(#linear-gradient-50)"/>
                    <circle id="Ellipse_32" data-name="Ellipse 32" cx="7.73" cy="7.73" r="7.73" transform="translate(1603.38 70.64)" fill="url(#linear-gradient-51)"/>
                    <circle id="Ellipse_33" data-name="Ellipse 33" cx="7.73" cy="7.73" r="7.73" transform="translate(1603.38 122.53)" fill="url(#linear-gradient-52)"/>
                    <circle id="Ellipse_34" data-name="Ellipse 34" cx="7.73" cy="7.73" r="7.73" transform="translate(1603.38 174.42)" fill="url(#linear-gradient-53)"/>
                    <circle id="Ellipse_35" data-name="Ellipse 35" cx="7.73" cy="7.73" r="7.73" transform="translate(1603.38 226.31)" fill="url(#linear-gradient-54)"/>
                    <circle id="Ellipse_36" data-name="Ellipse 36" cx="7.73" cy="7.73" r="7.73" transform="translate(1603.38 278.21)" fill="url(#linear-gradient-55)"/>
                    <circle id="Ellipse_37" data-name="Ellipse 37" cx="7.73" cy="7.73" r="7.73" transform="translate(1603.38 330.1)" fill="url(#linear-gradient-56)"/>
                    <path id="Path_27" data-name="Path 27" d="M1568.05,34.2a7.73,7.73,0,1,1,7.73-7.73A7.729,7.729,0,0,1,1568.05,34.2Z" fill="url(#linear-gradient-57)"/>
                    <circle id="Ellipse_38" data-name="Ellipse 38" cx="7.73" cy="7.73" r="7.73" transform="translate(1560.32 70.64)" fill="url(#linear-gradient-58)"/>
                    <circle id="Ellipse_39" data-name="Ellipse 39" cx="7.73" cy="7.73" r="7.73" transform="translate(1560.32 122.53)" fill="url(#linear-gradient-59)"/>
                    <circle id="Ellipse_40" data-name="Ellipse 40" cx="7.73" cy="7.73" r="7.73" transform="translate(1560.32 174.42)" fill="url(#linear-gradient-60)"/>
                    <circle id="Ellipse_41" data-name="Ellipse 41" cx="7.73" cy="7.73" r="7.73" transform="translate(1560.32 226.31)" fill="url(#linear-gradient-61)"/>
                    <circle id="Ellipse_42" data-name="Ellipse 42" cx="7.73" cy="7.73" r="7.73" transform="translate(1560.32 278.21)" fill="url(#linear-gradient-62)"/>
                    <circle id="Ellipse_43" data-name="Ellipse 43" cx="7.73" cy="7.73" r="7.73" transform="translate(1560.32 330.1)" fill="url(#linear-gradient-63)"/>
                    <path id="Path_28" data-name="Path 28" d="M1524.99,34.2a7.73,7.73,0,1,1,7.73-7.73A7.729,7.729,0,0,1,1524.99,34.2Z" fill="url(#linear-gradient-64)"/>
                    <circle id="Ellipse_44" data-name="Ellipse 44" cx="7.73" cy="7.73" r="7.73" transform="translate(1517.26 70.64)" fill="url(#linear-gradient-65)"/>
                    <circle id="Ellipse_45" data-name="Ellipse 45" cx="7.73" cy="7.73" r="7.73" transform="translate(1517.26 122.53)" fill="url(#linear-gradient-66)"/>
                    <circle id="Ellipse_46" data-name="Ellipse 46" cx="7.73" cy="7.73" r="7.73" transform="translate(1517.26 174.42)" fill="url(#linear-gradient-67)"/>
                    <circle id="Ellipse_47" data-name="Ellipse 47" cx="7.73" cy="7.73" r="7.73" transform="translate(1517.26 226.31)" fill="url(#linear-gradient-68)"/>
                    <circle id="Ellipse_48" data-name="Ellipse 48" cx="7.73" cy="7.73" r="7.73" transform="translate(1517.26 278.21)" fill="url(#linear-gradient-69)"/>
                    <circle id="Ellipse_49" data-name="Ellipse 49" cx="7.73" cy="7.73" r="7.73" transform="translate(1517.26 330.1)" fill="url(#linear-gradient-70)"/>
                    <path id="Path_29" data-name="Path 29" d="M1481.93,34.2a7.73,7.73,0,1,1,7.73-7.73A7.729,7.729,0,0,1,1481.93,34.2Z" fill="url(#linear-gradient-71)"/>
                    <circle id="Ellipse_50" data-name="Ellipse 50" cx="7.73" cy="7.73" r="7.73" transform="translate(1474.2 70.64)" fill="url(#linear-gradient-72)"/>
                    <circle id="Ellipse_51" data-name="Ellipse 51" cx="7.73" cy="7.73" r="7.73" transform="translate(1474.2 122.53)" fill="url(#linear-gradient-73)"/>
                    <circle id="Ellipse_52" data-name="Ellipse 52" cx="7.73" cy="7.73" r="7.73" transform="translate(1474.2 174.42)" fill="url(#linear-gradient-74)"/>
                    <circle id="Ellipse_53" data-name="Ellipse 53" cx="7.73" cy="7.73" r="7.73" transform="translate(1474.2 226.31)" fill="url(#linear-gradient-75)"/>
                    <circle id="Ellipse_54" data-name="Ellipse 54" cx="7.73" cy="7.73" r="7.73" transform="translate(1474.2 278.21)" fill="url(#linear-gradient-76)"/>
                    <circle id="Ellipse_55" data-name="Ellipse 55" cx="7.73" cy="7.73" r="7.73" transform="translate(1474.2 330.1)" fill="url(#linear-gradient-77)"/>
                    <path id="Path_30" data-name="Path 30" d="M1438.87,34.2a7.73,7.73,0,1,1,7.73-7.73A7.729,7.729,0,0,1,1438.87,34.2Z" fill="url(#linear-gradient-78)"/>
                    <circle id="Ellipse_56" data-name="Ellipse 56" cx="7.73" cy="7.73" r="7.73" transform="translate(1431.14 70.64)" fill="url(#linear-gradient-79)"/>
                    <circle id="Ellipse_57" data-name="Ellipse 57" cx="7.73" cy="7.73" r="7.73" transform="translate(1431.14 122.53)" fill="url(#linear-gradient-80)"/>
                    <circle id="Ellipse_58" data-name="Ellipse 58" cx="7.73" cy="7.73" r="7.73" transform="translate(1431.14 174.42)" fill="url(#linear-gradient-81)"/>
                    <circle id="Ellipse_59" data-name="Ellipse 59" cx="7.73" cy="7.73" r="7.73" transform="translate(1431.14 226.31)" fill="url(#linear-gradient-82)"/>
                    <circle id="Ellipse_60" data-name="Ellipse 60" cx="7.73" cy="7.73" r="7.73" transform="translate(1431.14 278.21)" fill="url(#linear-gradient-83)"/>
                    <circle id="Ellipse_61" data-name="Ellipse 61" cx="7.73" cy="7.73" r="7.73" transform="translate(1431.14 330.1)" fill="url(#linear-gradient-84)"/>
                </g>
            </svg>

            <svg id="preload_shape2" className="preload_shapes" xmlns="http://www.w3.org/2000/svg" width="557.3" height="555" viewBox="0 0 557.3 555">
                <g id="Group_4" data-name="Group 4" transform="translate(-1.8 -382.11)" opacity="0.05">
                    <path id="Path_33" data-name="Path 33" d="M542.98,929.05a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,542.98,929.05Z" fill="#f48572"/>
                    <path id="Path_34" data-name="Path 34" d="M488.86,929.05a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,488.86,929.05Z" fill="#f48572"/>
                    <path id="Path_35" data-name="Path 35" d="M434.74,929.05a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,434.74,929.05Z" fill="#f48572"/>
                    <path id="Path_36" data-name="Path 36" d="M380.62,929.05a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,380.62,929.05Z" fill="#f48572"/>
                    <path id="Path_37" data-name="Path 37" d="M326.51,929.05a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,326.51,929.05Z" fill="#f48572"/>
                    <path id="Path_38" data-name="Path 38" d="M272.39,929.05a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,272.39,929.05Z" fill="#f48572"/>
                    <path id="Path_39" data-name="Path 39" d="M218.27,929.05a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,218.27,929.05Z" fill="#f48572"/>
                    <path id="Path_40" data-name="Path 40" d="M164.15,929.05a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,164.15,929.05Z" fill="#f48572"/>
                    <path id="Path_41" data-name="Path 41" d="M110.03,929.05a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,110.03,929.05Z" fill="#f48572"/>
                    <path id="Path_42" data-name="Path 42" d="M55.91,929.05a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                    <path id="Path_43" data-name="Path 43" d="M1.8,929.05a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                    <path id="Path_44" data-name="Path 44" d="M542.98,884.14a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,542.98,884.14Z" fill="#f48572"/>
                    <path id="Path_45" data-name="Path 45" d="M488.86,884.14a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,488.86,884.14Z" fill="#f48572"/>
                    <path id="Path_46" data-name="Path 46" d="M434.74,884.14a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,434.74,884.14Z" fill="#f48572"/>
                    <path id="Path_47" data-name="Path 47" d="M380.62,884.14a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,380.62,884.14Z" fill="#f48572"/>
                    <path id="Path_48" data-name="Path 48" d="M326.51,884.14a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,326.51,884.14Z" fill="#f48572"/>
                    <path id="Path_49" data-name="Path 49" d="M272.39,884.14a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,272.39,884.14Z" fill="#f48572"/>
                    <path id="Path_50" data-name="Path 50" d="M218.27,884.14a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,218.27,884.14Z" fill="#f48572"/>
                    <path id="Path_51" data-name="Path 51" d="M164.15,884.14a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,164.15,884.14Z" fill="#f48572"/>
                    <path id="Path_52" data-name="Path 52" d="M110.03,884.14a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,110.03,884.14Z" fill="#f48572"/>
                    <path id="Path_53" data-name="Path 53" d="M55.91,884.14a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                    <path id="Path_54" data-name="Path 54" d="M1.8,884.14a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                    <path id="Path_55" data-name="Path 55" d="M542.98,839.23a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,542.98,839.23Z" fill="#f48572"/>
                    <path id="Path_56" data-name="Path 56" d="M488.86,839.23a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,488.86,839.23Z" fill="#f48572"/>
                    <path id="Path_57" data-name="Path 57" d="M434.74,839.23a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,434.74,839.23Z" fill="#f48572"/>
                    <path id="Path_58" data-name="Path 58" d="M380.62,839.23a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,380.62,839.23Z" fill="#f48572"/>
                    <path id="Path_59" data-name="Path 59" d="M326.51,839.23a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,326.51,839.23Z" fill="#f48572"/>
                    <path id="Path_60" data-name="Path 60" d="M272.39,839.23a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,272.39,839.23Z" fill="#f48572"/>
                    <path id="Path_61" data-name="Path 61" d="M218.27,839.23a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,218.27,839.23Z" fill="#f48572"/>
                    <path id="Path_62" data-name="Path 62" d="M164.15,839.23a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,164.15,839.23Z" fill="#f48572"/>
                    <path id="Path_63" data-name="Path 63" d="M110.03,839.23a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,110.03,839.23Z" fill="#f48572"/>
                    <path id="Path_64" data-name="Path 64" d="M55.91,839.23a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                    <path id="Path_65" data-name="Path 65" d="M1.8,839.23a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                    <path id="Path_66" data-name="Path 66" d="M542.98,794.33a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,542.98,794.33Z" fill="#f48572"/>
                    <path id="Path_67" data-name="Path 67" d="M488.86,794.33a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,488.86,794.33Z" fill="#f48572"/>
                    <path id="Path_68" data-name="Path 68" d="M434.74,794.33a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,434.74,794.33Z" fill="#f48572"/>
                    <path id="Path_69" data-name="Path 69" d="M380.62,794.33a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,380.62,794.33Z" fill="#f48572"/>
                    <path id="Path_70" data-name="Path 70" d="M326.51,794.33a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,326.51,794.33Z" fill="#f48572"/>
                    <path id="Path_71" data-name="Path 71" d="M272.39,794.33a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,272.39,794.33Z" fill="#f48572"/>
                    <path id="Path_72" data-name="Path 72" d="M218.27,794.33a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,218.27,794.33Z" fill="#f48572"/>
                    <path id="Path_73" data-name="Path 73" d="M164.15,794.33a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,164.15,794.33Z" fill="#f48572"/>
                    <path id="Path_74" data-name="Path 74" d="M110.03,794.33a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,110.03,794.33Z" fill="#f48572"/>
                    <path id="Path_75" data-name="Path 75" d="M55.91,794.33a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                    <path id="Path_76" data-name="Path 76" d="M1.8,794.33a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                    <path id="Path_77" data-name="Path 77" d="M542.98,749.42a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,542.98,749.42Z" fill="#f48572"/>
                    <path id="Path_78" data-name="Path 78" d="M488.86,749.42a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,488.86,749.42Z" fill="#f48572"/>
                    <path id="Path_79" data-name="Path 79" d="M434.74,749.42a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,434.74,749.42Z" fill="#f48572"/>
                    <path id="Path_80" data-name="Path 80" d="M380.62,749.42a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,380.62,749.42Z" fill="#f48572"/>
                    <path id="Path_81" data-name="Path 81" d="M326.51,749.42a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,326.51,749.42Z" fill="#f48572"/>
                    <path id="Path_82" data-name="Path 82" d="M272.39,749.42a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,272.39,749.42Z" fill="#f48572"/>
                    <path id="Path_83" data-name="Path 83" d="M218.27,749.42a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,218.27,749.42Z" fill="#f48572"/>
                    <path id="Path_84" data-name="Path 84" d="M164.15,749.42a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,164.15,749.42Z" fill="#f48572"/>
                    <path id="Path_85" data-name="Path 85" d="M110.03,749.42a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,110.03,749.42Z" fill="#f48572"/>
                    <path id="Path_86" data-name="Path 86" d="M55.91,749.42a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                    <path id="Path_87" data-name="Path 87" d="M1.8,749.42a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                    <path id="Path_88" data-name="Path 88" d="M542.98,704.51a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,542.98,704.51Z" fill="#f48572"/>
                    <path id="Path_89" data-name="Path 89" d="M488.86,704.51a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,488.86,704.51Z" fill="#f48572"/>
                    <path id="Path_90" data-name="Path 90" d="M434.74,704.51a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,434.74,704.51Z" fill="#f48572"/>
                    <path id="Path_91" data-name="Path 91" d="M380.62,704.51a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,380.62,704.51Z" fill="#f48572"/>
                    <path id="Path_92" data-name="Path 92" d="M326.51,704.51a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,326.51,704.51Z" fill="#f48572"/>
                    <path id="Path_93" data-name="Path 93" d="M272.39,704.51a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,272.39,704.51Z" fill="#f48572"/>
                    <path id="Path_94" data-name="Path 94" d="M218.27,704.51a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,218.27,704.51Z" fill="#f48572"/>
                    <path id="Path_95" data-name="Path 95" d="M164.15,704.51a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,164.15,704.51Z" fill="#f48572"/>
                    <path id="Path_96" data-name="Path 96" d="M110.03,704.51a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,110.03,704.51Z" fill="#f48572"/>
                    <path id="Path_97" data-name="Path 97" d="M55.91,704.51a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                    <path id="Path_98" data-name="Path 98" d="M1.8,704.51a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                    <circle id="Ellipse_62" data-name="Ellipse 62" cx="8.06" cy="8.06" r="8.06" transform="translate(542.98 651.55)" fill="#f48572"/>
                    <path id="Path_99" data-name="Path 99" d="M488.86,659.61a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,488.86,659.61Z" fill="#f48572"/>
                    <path id="Path_100" data-name="Path 100" d="M434.74,659.61a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,434.74,659.61Z" fill="#f48572"/>
                    <circle id="Ellipse_63" data-name="Ellipse 63" cx="8.06" cy="8.06" r="8.06" transform="translate(380.62 651.55)" fill="#f48572"/>
                    <circle id="Ellipse_64" data-name="Ellipse 64" cx="8.06" cy="8.06" r="8.06" transform="translate(326.51 651.55)" fill="#f48572"/>
                    <path id="Path_101" data-name="Path 101" d="M272.39,659.61a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,272.39,659.61Z" fill="#f48572"/>
                    <path id="Path_102" data-name="Path 102" d="M218.27,659.61a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,218.27,659.61Z" fill="#f48572"/>
                    <circle id="Ellipse_65" data-name="Ellipse 65" cx="8.06" cy="8.06" r="8.06" transform="translate(164.15 651.55)" fill="#f48572"/>
                    <circle id="Ellipse_66" data-name="Ellipse 66" cx="8.06" cy="8.06" r="8.06" transform="translate(110.03 651.55)" fill="#f48572"/>
                    <path id="Path_103" data-name="Path 103" d="M55.91,659.61a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                    <path id="Path_104" data-name="Path 104" d="M1.8,659.61a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                    <circle id="Ellipse_67" data-name="Ellipse 67" cx="8.06" cy="8.06" r="8.06" transform="translate(542.98 606.64)" fill="#f48572"/>
                    <path id="Path_105" data-name="Path 105" d="M488.86,614.7a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,488.86,614.7Z" fill="#f48572"/>
                    <path id="Path_106" data-name="Path 106" d="M434.74,614.7a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,434.74,614.7Z" fill="#f48572"/>
                    <circle id="Ellipse_68" data-name="Ellipse 68" cx="8.06" cy="8.06" r="8.06" transform="translate(380.62 606.64)" fill="#f48572"/>
                    <circle id="Ellipse_69" data-name="Ellipse 69" cx="8.06" cy="8.06" r="8.06" transform="translate(326.51 606.64)" fill="#f48572"/>
                    <path id="Path_107" data-name="Path 107" d="M272.39,614.7a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,272.39,614.7Z" fill="#f48572"/>
                    <path id="Path_108" data-name="Path 108" d="M218.27,614.7a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,218.27,614.7Z" fill="#f48572"/>
                    <circle id="Ellipse_70" data-name="Ellipse 70" cx="8.06" cy="8.06" r="8.06" transform="translate(164.15 606.64)" fill="#f48572"/>
                    <circle id="Ellipse_71" data-name="Ellipse 71" cx="8.06" cy="8.06" r="8.06" transform="translate(110.03 606.64)" fill="#f48572"/>
                    <path id="Path_109" data-name="Path 109" d="M55.91,614.7a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                    <path id="Path_110" data-name="Path 110" d="M1.8,614.7a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,1.8,614.7Z" fill="#f48572"/>
                    <circle id="Ellipse_72" data-name="Ellipse 72" cx="8.06" cy="8.06" r="8.06" transform="translate(542.98 561.73)" fill="#f48572"/>
                    <path id="Path_111" data-name="Path 111" d="M488.86,569.79a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,488.86,569.79Z" fill="#f48572"/>
                    <path id="Path_112" data-name="Path 112" d="M434.74,569.79a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,434.74,569.79Z" fill="#f48572"/>
                    <circle id="Ellipse_73" data-name="Ellipse 73" cx="8.06" cy="8.06" r="8.06" transform="translate(380.62 561.73)" fill="#f48572"/>
                    <circle id="Ellipse_74" data-name="Ellipse 74" cx="8.06" cy="8.06" r="8.06" transform="translate(326.51 561.73)" fill="#f48572"/>
                    <path id="Path_113" data-name="Path 113" d="M272.39,569.79a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,272.39,569.79Z" fill="#f48572"/>
                    <path id="Path_114" data-name="Path 114" d="M218.27,569.79a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,218.27,569.79Z" fill="#f48572"/>
                    <circle id="Ellipse_75" data-name="Ellipse 75" cx="8.06" cy="8.06" r="8.06" transform="translate(164.15 561.73)" fill="#f48572"/>
                    <circle id="Ellipse_76" data-name="Ellipse 76" cx="8.06" cy="8.06" r="8.06" transform="translate(110.03 561.73)" fill="#f48572"/>
                    <path id="Path_115" data-name="Path 115" d="M55.91,569.79a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                    <path id="Path_116" data-name="Path 116" d="M1.8,569.79a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                    <circle id="Ellipse_77" data-name="Ellipse 77" cx="8.06" cy="8.06" r="8.06" transform="translate(542.98 516.83)" fill="#f48572"/>
                    <path id="Path_117" data-name="Path 117" d="M488.86,524.89a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,488.86,524.89Z" fill="#f48572"/>
                    <path id="Path_118" data-name="Path 118" d="M434.74,524.89a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,434.74,524.89Z" fill="#f48572"/>
                    <circle id="Ellipse_78" data-name="Ellipse 78" cx="8.06" cy="8.06" r="8.06" transform="translate(380.62 516.83)" fill="#f48572"/>
                    <circle id="Ellipse_79" data-name="Ellipse 79" cx="8.06" cy="8.06" r="8.06" transform="translate(326.51 516.83)" fill="#f48572"/>
                    <path id="Path_119" data-name="Path 119" d="M272.39,524.89a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,272.39,524.89Z" fill="#f48572"/>
                    <path id="Path_120" data-name="Path 120" d="M218.27,524.89a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,218.27,524.89Z" fill="#f48572"/>
                    <circle id="Ellipse_80" data-name="Ellipse 80" cx="8.06" cy="8.06" r="8.06" transform="translate(164.15 516.83)" fill="#f48572"/>
                    <circle id="Ellipse_81" data-name="Ellipse 81" cx="8.06" cy="8.06" r="8.06" transform="translate(110.03 516.83)" fill="#f48572"/>
                    <path id="Path_121" data-name="Path 121" d="M55.91,524.89a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                    <path id="Path_122" data-name="Path 122" d="M1.8,524.89a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                    <circle id="Ellipse_82" data-name="Ellipse 82" cx="8.06" cy="8.06" r="8.06" transform="translate(542.98 471.92)" fill="#f48572"/>
                    <path id="Path_123" data-name="Path 123" d="M488.86,479.98a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,488.86,479.98Z" fill="#f48572"/>
                    <path id="Path_124" data-name="Path 124" d="M434.74,479.98a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,434.74,479.98Z" fill="#f48572"/>
                    <circle id="Ellipse_83" data-name="Ellipse 83" cx="8.06" cy="8.06" r="8.06" transform="translate(380.62 471.92)" fill="#f48572"/>
                    <circle id="Ellipse_84" data-name="Ellipse 84" cx="8.06" cy="8.06" r="8.06" transform="translate(326.51 471.92)" fill="#f48572"/>
                    <path id="Path_125" data-name="Path 125" d="M272.39,479.98a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,272.39,479.98Z" fill="#f48572"/>
                    <path id="Path_126" data-name="Path 126" d="M218.27,479.98a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,218.27,479.98Z" fill="#f48572"/>
                    <circle id="Ellipse_85" data-name="Ellipse 85" cx="8.06" cy="8.06" r="8.06" transform="translate(164.15 471.92)" fill="#f48572"/>
                    <circle id="Ellipse_86" data-name="Ellipse 86" cx="8.06" cy="8.06" r="8.06" transform="translate(110.03 471.92)" fill="#f48572"/>
                    <path id="Path_127" data-name="Path 127" d="M55.91,479.98a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                    <path id="Path_128" data-name="Path 128" d="M1.8,479.98a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                    <circle id="Ellipse_87" data-name="Ellipse 87" cx="8.06" cy="8.06" r="8.06" transform="translate(542.98 427.01)" fill="#f48572"/>
                    <path id="Path_129" data-name="Path 129" d="M488.86,435.07a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,488.86,435.07Z" fill="#f48572"/>
                    <path id="Path_130" data-name="Path 130" d="M434.74,435.07a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,434.74,435.07Z" fill="#f48572"/>
                    <circle id="Ellipse_88" data-name="Ellipse 88" cx="8.06" cy="8.06" r="8.06" transform="translate(380.62 427.01)" fill="#f48572"/>
                    <circle id="Ellipse_89" data-name="Ellipse 89" cx="8.06" cy="8.06" r="8.06" transform="translate(326.51 427.01)" fill="#f48572"/>
                    <path id="Path_131" data-name="Path 131" d="M272.39,435.07a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,272.39,435.07Z" fill="#f48572"/>
                    <path id="Path_132" data-name="Path 132" d="M218.27,435.07a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,218.27,435.07Z" fill="#f48572"/>
                    <circle id="Ellipse_90" data-name="Ellipse 90" cx="8.06" cy="8.06" r="8.06" transform="translate(164.15 427.01)" fill="#f48572"/>
                    <circle id="Ellipse_91" data-name="Ellipse 91" cx="8.06" cy="8.06" r="8.06" transform="translate(110.03 427.01)" fill="#f48572"/>
                    <path id="Path_133" data-name="Path 133" d="M55.91,435.07a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                    <path id="Path_134" data-name="Path 134" d="M1.8,435.07a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                    <circle id="Ellipse_92" data-name="Ellipse 92" cx="8.06" cy="8.06" r="8.06" transform="translate(542.98 382.11)" fill="#f48572"/>
                    <path id="Path_135" data-name="Path 135" d="M488.86,390.17a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,488.86,390.17Z" fill="#f48572"/>
                    <path id="Path_136" data-name="Path 136" d="M434.74,390.17a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,434.74,390.17Z" fill="#f48572"/>
                    <circle id="Ellipse_93" data-name="Ellipse 93" cx="8.06" cy="8.06" r="8.06" transform="translate(380.62 382.11)" fill="#f48572"/>
                    <circle id="Ellipse_94" data-name="Ellipse 94" cx="8.06" cy="8.06" r="8.06" transform="translate(326.51 382.11)" fill="#f48572"/>
                    <path id="Path_137" data-name="Path 137" d="M272.39,390.17a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,272.39,390.17Z" fill="#f48572"/>
                    <path id="Path_138" data-name="Path 138" d="M218.27,390.17a8.06,8.06,0,1,1,8.06,8.06A8.062,8.062,0,0,1,218.27,390.17Z" fill="#f48572"/>
                    <circle id="Ellipse_95" data-name="Ellipse 95" cx="8.06" cy="8.06" r="8.06" transform="translate(164.15 382.11)" fill="#f48572"/>
                    <circle id="Ellipse_96" data-name="Ellipse 96" cx="8.06" cy="8.06" r="8.06" transform="translate(110.03 382.11)" fill="#f48572"/>
                    <path id="Path_139" data-name="Path 139" d="M55.91,390.17a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                    <path id="Path_140" data-name="Path 140" d="M1.8,390.17a8.06,8.06,0,1,1,8.06,8.06,8.062,8.062,0,0,1-8.06-8.06Z" fill="#f48572"/>
                </g>
            </svg>

            <svg id="preload_shape3" className="preload_shapes" xmlns="http://www.w3.org/2000/svg" width="366.41" height="317.33" viewBox="0 0 366.41 317.33">
                <path id="Path_6" data-name="Path 6" d="M2005.28,421.01l-183.2,317.33L1638.87,421.01Z" transform="translate(-1638.87 -421.01)" fill="#f79b79" opacity="0.05"/>
            </svg>

            <svg id="preload_shape4" className="preload_shapes" xmlns="http://www.w3.org/2000/svg" width="1001.101" height="511.134" viewBox="0 0 1001.101 511.134">
                <path id="Path_31" data-name="Path 31" d="M590.87,1137.51c6.69-187.89,163.56-335.64,351.88-330.59s337.04,161.01,333.64,348.99l157.73,4.23c5.73-275.09-211.71-503.56-487.14-510.95S442.17,858.27,433.13,1133.27l157.73,4.23Z" transform="translate(-433.13 -649.006)" fill="#f48572" opacity="0.05"/>
            </svg>

            <svg id="preload_shape5" className="preload_shapes" xmlns="http://www.w3.org/2000/svg" width="767.69" height="450.39" viewBox="0 0 767.69 450.39">
                <path id="Path_32" data-name="Path 32" d="M795.51-216.38H1245.9L928.6,234.01H478.21Z" transform="translate(-478.21 216.38)" fill="#ed3d65" opacity="0.05"/>
            </svg>

            <svg id="preload_shape6" className="preload_shapes" xmlns="http://www.w3.org/2000/svg" width="511.134" height="1001.101" viewBox="0 0 511.134 1001.101">
                <path id="Path_141" data-name="Path 141" d="M1778.7,1140.28c-187.89-6.69-335.64-163.56-330.59-351.88s161.01-337.04,348.99-333.64l4.23-157.73c-275.09-5.73-503.56,211.71-510.95,487.14s209.08,504.81,484.08,513.85l4.23-157.73Z" transform="translate(-1290.196 -296.919)" fill="#ed3d65" opacity="0.15"/>
            </svg>


            <figure className={`${isPreOut ? "slide" : ""}`}>
                <img src={loader} alt="loaderGif" />
            </figure>
    </div>
    );
}

export default Preload;