// import logo from './logo.svg';
import {Routes, Route, Navigate } from 'react-router-dom'

import './reset.css'; 
import './App.css';

import { Cursor } from './libs/cursor';
import './libs/cursor.css';

import Preload from './components/Preload/Preload';
import Intro from './components/Intro/Intro';

import { useEffect, useState } from 'react';

function App() {
  const [isPreOut, setIsPreOut] = useState(false);
  const [isIntroIn, setIsIntroIn] = useState(false);

  
  // ******************** overlay timing function for loading ********************
  useEffect(() => {
    const startTime = new Date().getTime();

    // simulate an asynchronous operation (replace this with your actual async logic)
    const asyncOperation = () => {
      return new Promise(resolve => {

        // simulate an API call or asynchronous logic
        setTimeout(() => {
          resolve();
        }, 1500);
      });
    };

    asyncOperation().then(() => {
      const endTime = new Date().getTime();
      const elapsedTime = endTime - startTime;

      // if the elapsed time is less than 2000 milliseconds (2 seconds),
      // use setTimeout to wait for the remaining time before setting isPageLoaded.
      if( elapsedTime < 2000 ) {
        const remainingTime = 2000 - elapsedTime;
        setTimeout(() => {
          setIsPreOut(true);
        }, remainingTime);
      } else {

        // if the elapsed time is 2 seconds or more, set isPageLoaded immediately.
        setIsPreOut(true);
        
      }
    });
  }, []);

  useEffect(() => {

    if( isPreOut ) {

      // set a timeout to add the "load" class to Intro after the preload animation finishes
      setTimeout(() => {
        setIsIntroIn(true);

        // ******************** define new cursor ********************
        const cursor = new Cursor(document.querySelector('.cursor'));

      }, 1000);
    }
  }, [isPreOut]);

  return (
    <div className={`App ${isIntroIn ? 'intro-in' : ""} ${isPreOut ? "pre-out" : ""}`}>
      
      {!isIntroIn && <Preload isPreOut={isPreOut} />}
      {isPreOut && <Navigate to="/home" replace />}
      <Routes>
        <Route path="/home" element={<Intro isIntroIn={isIntroIn} isPreOut={isPreOut} />} />
      </Routes>

      <svg className="cursor" width="80" height="80" viewBox="0 0 80 80">
        <defs>
        <linearGradient id="g593" gradientUnits="userSpaceOnUse" x1="100%" y1="100%" x2="0%" y2="0%">
          <stop stopColor="#ED3D65" offset="0"/><stop stopColor="#F79B79" offset="1"/>
        </linearGradient>
        </defs>
        <circle fill="url(#g593)" className="cursor__inner" cx="40" cy="40" r="20"/>
      </svg>
    </div>
  );
}

export default App;